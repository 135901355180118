import React, { useContext } from "react";
import { UserContext } from "../../UserContext";
import { appCardData } from "../../utils/appCardData";
import { MdEast, MdLock } from "react-icons/md";
import { Esp, Mosaic, Ovi, RoundTable } from "../../Logos";
import mixpanel from "mixpanel-browser";

const apps = {
  esp: {
    name: "ESP",
    logo: <Esp className="header--transition header__logo" fill="bluev2" hasWhiteDot width={22} height={19} />,
  },
  roundtable: {
    name: "Roundtable",
    logo: <RoundTable className="header--transition header__logo" fill="bluev2" hasWhiteDot width={18} height={23} />,
  },
  ovi: {
    name: "OVI",
    logo: <Ovi className="header--transition header__logo" fill="bluev2" hasWhiteDot width={27} height={21} />,
  },
  mbs: {
    name: "MBS",
    logo: <Mosaic className="header--transition header__logo" fill="bluev2" hasWhiteDot width={22} height={22} />,
  },
};

function renderGreeting() {
  const hours = new Date().getHours();

  if (24 >= hours && hours < 12) {
    return "Good Morning";
  } else if (12 <= hours && hours < 16) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
}

function TrackedLinkWrapper({ currentUser, mfr, app, children }) {
  if (!currentUser.permissions[app.id] || !currentUser.mfrPermissions[app.id]) return <>{children}</>;

  return (
    <a
      href={`https://${app.id}.${window.location.host}`}
      target="_blank"
      onClick={() => mixpanel.track("App Clicked", { "App Name": app.name })}
    >
      {children}
    </a>
  );
}

function AppBadge({ app }) {
  if (!app) return;

  return (
    <span className="header--transition header__badge display--flex flex--center pa--8 br--round position--rel left--16 mt--4">
      {apps[app].logo}
    </span>
  );
}

function AnimatedAppCard({ app, hasAccess, mfrHasAccess, children }) {
  return (
    <div style={{ transform: "scale(1)" }}>
      <div className="header--transition header__app-card bg--mid-gray-v2 white br--12 pb--24 position--rel h--max-70px">
        <svg
          className="header--transition-fast header__svg position--abs top--neg160 left--280"
          width="300"
          height="200"
          fill="#4586fd"
        >
          <rect width="200" height="200" />
        </svg>
        <header className="display--flex flex--content-between flex--items-center">
          <AppBadge app={app} />{" "}
          {hasAccess && mfrHasAccess ? (
            <MdEast className="header--animate-appear white--opacity60 position--rel top--8 right--12" size={24} />
          ) : (
            <MdLock className="header--transition header__lock white--opacity50 position--rel right--8 fs--10" />
          )}
        </header>
        <div className="header--transition header__app-name position--rel mb--12 text--c">
          <span className="header--transition lh--tight fs--10">{apps[app].name}</span>
        </div>
        <div className="header--transition header__copy-container display--grid ml--8 mb--8">
          <p className="fs--12 lh--copy">{children}</p>
        </div>
      </div>
    </div>
  );
}

export default function Header(props) {
  const { currentUser } = useContext(UserContext);

  return (
    <header className="display--flex fs--12">
      <div className="flex--item-grow1">
        <div className="mid-gray mb--12">{props.mfr.name}</div>
        <h2 className="bg--mid-gray-v2 pl--32 pv--20 br--12 fs--24 lh--tight fw--300 mr--16" style={{ height: 70 }}>
          {renderGreeting()}, <span className="blue fw--600">{currentUser.firstName}</span>. Welcome to MOSAIC.
        </h2>
      </div>

      <div>
        <div className="mid-gray mb--12">Applications</div>
        <div className="display--flex flex--items-start gap--16" style={{ height: 70 }}>
          {appCardData.map((app) => (
            <div className="z--100" key={app.id}>

              <TrackedLinkWrapper currentUser={currentUser} mfr={props.mfr.short_name} app={app}>
                <AnimatedAppCard
                  app={app.id}
                  hasAccess={currentUser.permissions[app.id]}
                  mfrHasAccess={currentUser.mfrPermissions[app.id]}
                >
                  {app.shortCopy}
                </AnimatedAppCard>
              </TrackedLinkWrapper>
            </div>
          ))}
        </div>
      </div>
    </header>
  );
}
