import React from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { Mosaic } from "../../Logos";

export default function EoyReportCard({ eoyReport }) {
  if (eoyReport) {
    return (
      <article className="bg--blue-v3 white tile position--rel">
        <SvgShapes />
        <SvgGraph />
        <a
          href={eoyReport.download_url}
          target="_blank"
          className="display--block pa--24 position--rel z--100"
        >
          <span className="display--inline-flex pa--8 br--6 mb--16 bg--another-blue-sigh">
            <Mosaic width={16} height={8} />
          </span>
          <h2 className="fs--40 fw--medium mb--8 lh--solid">
            {eoyReport.date}
          </h2>
          <p className="text--wrap">
            Your end of year report is now available.
          </p>
          <p className="mt--16 fs--12 display--flex">
            <span className="ml--auto display--inline-flex gap--4 bg--bluish-purple pv--8 ph--12 br--pill">
              View Report <MdOutlineFileDownload size={16} />
            </span>
          </p>
        </a>
      </article>
    );
  }
}

function SvgShapes() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="192"
      height="186"
      viewBox="0 0 192 186"
      fill="none"
      className="position--abs top--0 left--0"
    >
      <rect
        x="-.316"
        y=".425"
        width="16.003"
        height="15.868"
        rx="7.934"
        transform="rotate(81.698 72.45 76.397) skewX(.138)"
        stroke="#28D0FC"
        strokeWidth=".747"
      />
      <rect
        x="-7.5"
        y="165.5"
        width="20"
        height="20"
        rx="10"
        stroke="#28D0FC"
      />
      <path
        d="m146.613 19.178 12.715-1.887 1.871 12.822c-7.022 1.042-13.561-3.91-14.586-10.935z"
        fill="#3246D3"
      />
      <path
        fill="#28D0FC"
        d="m191.715 11.584-5.853 14.375-14.144-5.857 5.854-14.375z"
      />
    </svg>
  );
}

function SvgGraph() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="208"
      height="210"
      viewBox="0 0 208 210"
      fill="none"
      className="position--abs bottom--0 right--0"
    >
      <rect
        y="173"
        width="39"
        height="45"
        rx="5"
        fill="#2159EA"
        fillOpacity=".25"
      />
      <rect
        x="44"
        y="140"
        width="39"
        height="78"
        rx="5"
        fill="#2159EA"
        fillOpacity=".25"
      />
      <rect
        x="88"
        y="79"
        width="39"
        height="139"
        rx="5"
        fill="#2159EA"
        fillOpacity=".25"
      />
      <rect
        x="132"
        y="46"
        width="39"
        height="172"
        rx="5"
        fill="#2159EA"
        fillOpacity=".25"
      />
      <rect
        x="176"
        width="39"
        height="218"
        rx="5"
        fill="#2159EA"
        fillOpacity=".25"
      />
    </svg>
  );
}
