import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import { IconContext } from "react-icons";
import { MdDashboard, MdApps, MdOutlineSupervisedUserCircle, MdLogout, MdCircle } from "react-icons/md";
import { UserContext } from "./UserContext";
import { Mosaic } from "./Logos";
import Avatar from "./elements/Avatar";
import Tooltip from "./elements/Tooltip";
import mixpanel from "mixpanel-browser";

const currentPage = window.location.pathname.split("/").at(-1);
const MAIN_NAV_PAGES = ["landing", "apps", "users"];

const Nav = () => {
  const { currentUser } = useContext(UserContext);
  const mainNav = useRef(null);
  const [indicatorPosition, setIndicatorPosition] = useState(null);
  const showNavIndicator = MAIN_NAV_PAGES.includes(currentPage);
  const sideOffset = 12;

  useLayoutEffect(() => {
    if (showNavIndicator) {
      const activeEl = mainNav.current?.querySelector(".layout__nav__item--active")?.offsetTop;
      setIndicatorPosition({
        "--from-position": `${localStorage.getItem("prevPosition")}px`,
        "--to-position": `${activeEl}px`,
      });
      localStorage.setItem("prevPosition", activeEl);
    }
  }, []);

  function isActiveNavItem(page) {
    return page === currentPage;
  }

  function resetMixpanelSession() {
    mixpanel.reset();
    sessionStorage.removeItem("logged_in");
  }

  return (
    <nav className="layout__nav">
      <Tooltip
        side="right"
        sideOffset={sideOffset}
        trigger={
          <a href="/">
            <div className="layout__nav__item layout__nav__item--auto">
              <Mosaic width={35} />
            </div>
          </a>
        }
      >
        <span className="bg--gray-v2 fs--12 pa--8 br--8">MOSAIC Home</span>
      </Tooltip>

      <IconContext.Provider value={{ size: 24, className: "display--block" }}>
        <div className="display--flex flex--column gap--20 br--pill bg--dark-gray pv--24 position--rel" ref={mainNav}>
          {showNavIndicator && <div className="layout__nav-indicator" style={indicatorPosition}></div>}
          <Tooltip
            side="right"
            sideOffset={sideOffset}
            trigger={
              <div
                className={
                  isActiveNavItem("landing") ? "layout__nav__item layout__nav__item--active" : "layout__nav__item"
                }
              >
                <a href="/" data-cy="nav-link__dashboard">
                  <MdDashboard />
                </a>
              </div>
            }
          >
            <span className="bg--gray-v2 fs--12 pa--8 br--8">Home</span>
          </Tooltip>
          {/* <Tooltip
            side="right"
            sideOffset={sideOffset}
            trigger={
              <div
                className={
                  isActiveNavItem("apps") ? "layout__nav__item layout__nav__item--active" : "layout__nav__item"
                }
              >
                <a href={`/organization/${currentUser.organizationId}/apps`} data-cy="nav-link__apps">
                  <MdApps />
                </a>
              </div>
            }
          >
            <span className="bg--gray-v2 fs--12 pa--8 br--8">All Apps</span>
          </Tooltip> */}
          <Tooltip
            side="right"
            sideOffset={sideOffset}
            trigger={
              <div
                className={
                  isActiveNavItem("users") ? "layout__nav__item layout__nav__item--active" : "layout__nav__item"
                }
              >
                <a href={`/organization/${currentUser.organizationId}/users`} data-cy="nav-link__user-mgmt">
                  <MdOutlineSupervisedUserCircle />
                  {currentUser.hasPendingActions && (
                    <MdCircle
                      size={8}
                      color="#e91e63"
                      className="position--abs right--16 top--16"
                      data-cy="nav__pending-action-badge"
                    />
                  )}
                </a>
              </div>
            }
          >
            <span className="bg--gray-v2 fs--12 pa--8 br--8">User Management</span>
          </Tooltip>
        </div>
        <div>
          <Tooltip
            side="right"
            sideOffset={sideOffset}
            trigger={
              <div className="layout__nav__item layout__nav__item--avatar">
                <a href={`/organization/${currentUser.organizationId}/profile`} data-cy="nav-link__profile">
                  <Avatar
                    size="md"
                    color={isActiveNavItem("profile") ? "active-blue" : "inactive-gray"}
                    initials={currentUser.initials}
                  />
                </a>
              </div>
            }
          >
            <span className="bg--gray-v2 fs--12 pa--8 br--8">User Settings</span>
          </Tooltip>
          <Tooltip
            side="right"
            sideOffset={sideOffset}
            trigger={
              <div className="layout__nav__item" onClick={resetMixpanelSession}>
                <a href="/logout">
                  <MdLogout />
                </a>
              </div>
            }
          >
            <span className="bg--gray-v2 fs--12 pa--8 br--8">Log Out</span>
          </Tooltip>
        </div>
      </IconContext.Provider>
    </nav>
  );
};

export default Nav;
