import React from "react";
import { MdOutlinePeople } from "react-icons/md";
import ErrorCard from "../../elements/ErrorCard";
import BaseCard from "./BaseCard";
import { RoundTable } from "../../Logos";
import defaultImage from "../../../assets/images/roundtable.jpg";

export default function RoundtableCollectionCard({ recentCollection }) {
  const { authors, type, publish_at, img_url } = (recentCollection || {});

  if (!type) {
    return (
      <ErrorCard app="roundtable">
        We cannot retrieve the latest Roundtable article data at this time
      </ErrorCard>
    );
  }

  const cardCopy = (type) => {
    switch (type) {
      case "collection/research_report":
        return "The latest Roundtable Research Report is now available";
      case "collection/round_up":
        return `The ${
          formatDate(publish_at) || "latest"
        } Round Up is now available.`;
      default:
        return "Review industry-leading analysis";
    }
  };

  return (
    <BaseCard>
      <BaseCard.FullSection>
        <img
          src={img_url ?? defaultImage}
          alt="roundtable image"
          className="brtl--12 brtr--12"
          style={{ objectFit: "cover", width: "100%", height: 136 }}
        />
      </BaseCard.FullSection>
      <BaseCard.BadgeContainer className="bg--badge-transparent position--abs top--24">
        <RoundTable width={14} height={14} />
        <span>Roundtable</span>
      </BaseCard.BadgeContainer>
      <BaseCard.Section>
        <BaseCard.SecondaryText>Publications</BaseCard.SecondaryText>
        <BaseCard.CopyContainer>{cardCopy(type)}</BaseCard.CopyContainer>
        {authors && (
          <BaseCard.TagContainer className="border bc--badge br--6 mt--8">
            <MdOutlinePeople />{" "}
            <span>
              {authors?.length > 1 ? "Multiple Authors" : authors?.[0]?.name}
            </span>
          </BaseCard.TagContainer>
        )}
      </BaseCard.Section>
    </BaseCard>
  );
}

function formatDate(date) {
  return date && new Date(date).toLocaleDateString("en-US", { month: "long" });
}
